<template>
  <v-container class="px-6 d-block" fluid>
    <div class="d-flex align-center mb-6">
      <v-icon size="15" color="#4ab762">mdi-home</v-icon>
      <v-breadcrumbs :items="breadcrumbs" class="pa-0 ml-2">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <v-row>
      <v-col cols="12">
        <v-card
          class="rounded-lg"
          style="box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px"
        >
          <v-card-title
            class="subtitle-1 grey--text text--darken-2 font-weight-bold"
            >Data User</v-card-title
          >
          <v-card-text class="px-0 py-0">
            <div class="px-4 d-flex justify-space-between">
              <v-btn-toggle dense class="mb-2">
                <v-btn
                  color="#3498db"
                  :disabled="
                    loading ||
                    selected.user.length <= 0 ||
                    selected.user[0].publisher_bool
                  "
                  class="text-none"
                  text
                  small
                  @click="
                    setForm();
                    dialog.user = true;
                  "
                >
                  <v-icon size="16" color="#3498db"> mdi-account-sync </v-icon>
                  <span class="hidden-sm-and-down ml-1">Jadikan Publisher</span>
                </v-btn>
              </v-btn-toggle>
            </div>
            <v-data-table
              v-model="selected.user"
              :headers="headers.user"
              :items="dataGrid.user"
              :loading="loading"
              :options.sync="options.user"
              :server-items-length="totalData.user"
              loading-text="Sedang memuat"
              :no-data-text="'Data belum ada'"
              no-results-text="Data belum ada"
              sort-by-text="Urutkan berdasarkan"
              :items-per-page="10"
              height="calc(100vh - 340px)"
              fixed-header
              :footer-props="footerProps"
              item-key="id"
              flat
              single-select
              show-select
              class="elevation-0 custom-grid rounded-lg"
            >
              <template v-slot:[`item.name`]="{ item }">
                <v-list-item class="py-0 px-0">
                  <v-list-item-avatar
                    tile
                    size="32"
                    class="rounded mr-2"
                    color="#f7f7f7"
                  >
                    <span v-if="item.img_url == ''">{{
                      item.name | initial
                    }}</span>
                    <v-img v-else :src="item.img_url"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0" style="max-width: 220px">
                    <v-list-item-title
                      class="subtitle-2 grey--text text--darken-1"
                      >{{ item.name }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-slot:[`item.publisher_bool`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 152 219);
                    background-color: rgb(52 152 219 / 10%);
                  "
                  v-if="item.publisher_bool"
                >
                  Publisher
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(149 165 166);
                    background-color: rgb(149 165 166 / 10%);
                  "
                  v-else
                >
                  Bukan Publisher
                </v-chip>
              </template>

              <template v-slot:[`item.gender`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 152 219);
                    background-color: rgb(52 152 219 / 10%);
                  "
                  v-if="item.gender == 'Laki-Laki'"
                >
                  <v-icon left small color="rgb(52 152 219)"
                    >mdi-gender-male</v-icon
                  >Laki-laki
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(155 89 182);
                    background-color: rgb(155 89 182 / 10%);
                  "
                  v-else-if="item.gender == 'Perempuan'"
                >
                  <v-icon small left color="rgb(155 89 182)"
                    >mdi-gender-female</v-icon
                  >Perempuan
                </v-chip>
                <div v-else>-</div>
              </template>

              <template v-slot:[`item.active_bool`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(46 204 113);
                    background-color: rgb(46 204 113 / 10%);
                  "
                  v-if="item.active_bool"
                >
                  Aktif
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(231, 76, 60);
                    background-color: rgb(231 76 60 / 10%);
                  "
                  v-else
                >
                  Tidak Aktif
                </v-chip>
              </template>

              <template v-slot:[`item.contact`]="{ item }">
                {{ item.contact | phone_number | emptyData }}
              </template>

              <template v-slot:[`item.birthdate`]="{ item }">
                {{ item.birthdate | dateFull | emptyData }}
              </template>

              <template v-slot:[`item.created_datetime`]="{ item }">
                {{ item.created_datetime | datetime | emptyData }}
              </template>

              <template v-slot:[`item.last_login_datetime`]="{ item }">
                {{ item.last_login_datetime | datetime | emptyData }}
              </template>

              <template v-slot:[`footer.page-text`]="props">
                Menampilkan {{ props.pageStart }} - {{ props.pageStop }} data
                dari total {{ props.itemsLength }} data
                <v-btn
                  text
                  class="ml-4 text-none"
                  small
                  @click="
                    fetchData();
                    selected.user = [];
                  "
                  ><v-icon left>mdi-sync</v-icon> Perbarui</v-btn
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- FORM -->
    <v-dialog
      v-model="dialog.user"
      v-if="dialog.user"
      persistent
      no-click-animation
      max-width="450"
      transition="slide-x-reverse-transition"
      content-class="my-custom-dialog"
      scrollable
    >
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          Jadikan User Sebagai Publisher
          <div class="caption grey--text text--darken-1">
            Lengkapi form dibawah ini
          </div></v-card-title
        >
        <v-divider></v-divider>
        <v-card-text style="height: calc(100vh - 118px)" class="py-4 px-4">
          <v-form
            ref="form-user"
            v-model="valid"
            lazy-validation
            @keyup.native.enter="doSave"
          >
            <div class="subtitle-2">
              Nama Lengkap
              <small style="color: #3498db"
                ><i>dapat diubah di menu publisher</i></small
              >
            </div>
            <v-text-field
              dense
              solo
              color="#4ab762"
              type="text"
              class="rounded-lg mb-3"
              disabled
              v-model="form.name"
              readonly
            >
            </v-text-field>

            <div class="subtitle-2 mt-4">
              Pilih Organisasi
              <small style="color: #e74c3c"
                ><i> *wajib minimal 1 organisasi</i></small
              >
            </div>
            <v-autocomplete
              dense
              :items="organizationData"
              color="#4ab762"
              item-value="id"
              item-text="name"
              @keyup.delete="deleteOrganization"
              v-model="form.organization_id_array"
              :disabled="loading"
              solo
              class="rounded-lg mb-3"
              :error-messages="validation.organization_id_array"
              @keyup="validation.organization_id_array = ''"
              placeholder="Pilih disini"
              multiple
            >
            </v-autocomplete>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            rounded
            depressed
            class="text-none white--text text--lighten-3 flex-grow-1"
            color="#4ab762"
            @click="doSave"
          >
            <v-icon left>mdi-check-circle-outline</v-icon>Jadikan Publisher
          </v-btn>
          <v-btn
            rounded
            depressed
            outlined
            class="text-none"
            color="#e74c3c"
            width="130"
            @click="
              dialog.user = false;
              formReset();
              $refs['form-user'].resetValidation();
              validationReset();
            "
            >Batal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "User",

  data: () => ({
    breadcrumbs: [
      {
        text: "Dashboard",
        disabled: false,
        href: "/",
      },
      {
        text: "User",
        disabled: true,
        href: "/user",
      },
    ],

    loading: false,
    dialog: {
      user: false,
    },
    selected: {
      user: [],
    },
    headers: {
      user: [
        {
          text: "NAMA",
          width: "300",
          align: "start",
          sortable: true,
          value: "name",
        },

        {
          text: "USERNAME",
          width: "250",
          align: "start",
          sortable: true,
          value: "username",
        },
        {
          text: "STATUS PUBLISHER",
          width: "180",
          align: "center",
          sortable: true,
          value: "publisher_bool",
        },
        {
          text: "STATUS USER",
          width: "140",
          align: "center",
          sortable: true,
          value: "active_bool",
        },
        {
          text: "EMAIL",
          width: "300",
          align: "start",
          sortable: true,
          value: "email",
        },
        {
          text: "NO. HANDPHONE",
          width: "160",
          value: "contact",
          align: "start",
        },
        {
          text: "JENIS KELAMIN",
          width: "180",
          value: "gender",
          align: "center",
        },
        {
          text: "TGL. LAHIR",
          width: "180",
          value: "birthdate",
          align: "center",
        },
        {
          text: "TGL. GABUNG",
          width: "180",
          value: "created_datetime",
          align: "center",
        },
        {
          text: "TERAKHIR LOGIN",
          width: "180",
          value: "last_login_datetime",
          align: "center",
        },
      ],
    },
    dataGrid: {
      user: [],
    },
    options: {
      user: {},
    },
    totalData: {
      user: 0,
    },
    footerProps: {
      "show-current-page": true,
      "show-first-last-page": true,
      "items-per-page-options": [10, 15, 30, 50, 100],
      "items-per-page-text": "Data per halaman",
      "page-text": "{0} - {1} dari total {2}",
    },

    valid: true,
    form: {
      id: "",
      name: "",
      organization_id_array: ["1"],
    },
    validation: {
      id: "",
      organization_id_array: "",
    },

    organizationData: [],
  }),

  watch: {
    "options.user": {
      async handler() {
        this.selected.user = [];
        await this.fetchData();
      },
      deep: true,
    },
  },

  mounted() {
    this.fetchOrganization();
  },

  methods: {
    formReset() {
      Object.assign(this.form, {
        id: "",
        name: "",
        organization_id_array: ["1"],
      });
    },
    validationReset() {
      Object.assign(this.validation, {
        id: "",
        organization_id_array: "",
      });
    },
    setForm() {
      let selected = this.selected.user[0];
      Object.assign(this.form, {
        id: selected.id,
        name: selected.name,
        organization_id_array: ["1"],
      });
    },
    async doSave() {
      let isValid = this.$refs["form-user"].validate();

      if (isValid) {
        this.$store.state.overlay.show = true;
        this.$store.state.overlay.text = "Menjadikan publisher...";

        let response = await this.$post(
          "admin/user/add_publisher_from_existing_user",
          this.form
        );

        this.$store.state.overlay.show = false;

        if (response.status == 200) {
          this.$store.state.snackbar = {
            show: true,
            text: "Berhasil menjadikan publisher",
            color: "#2ecc71",
          };
          this.dialog.user = false;

          this.formReset();
          this.$refs["form-user"].resetValidation();

          this.validationReset();

          this.selected.user = [];
          this.fetchData();
        } else if (response.status == 412) {
          this.$store.state.snackbar = {
            show: true,
            text: "Cek kembali form Anda",
            color: "#e74c3c",
          };

          Object.assign(this.validation, response.results.data);
        } else if (response.status == 400) {
          this.$store.state.snackbar = {
            show: true,
            text: response.message,
            color: "#e74c3c",
          };
        }
      }
    },

    async fetchOrganization() {
      this.$store.state.overlay.show = true;
      this.$store.state.overlay.text = "Memuat data organisasi...";

      const response = await this.$get(`organization`);

      if (response.status === 200) {
        this.organizationData = response.results.data;
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data organisasi.",
          color: "#f39c12",
        };
      }

      this.$store.state.overlay.show = false;
    },
    deleteOrganization() {
      if (this.form.organization_id_array < 1) {
        this.form.organization_id_array = ["1"];
      }
    },
    async fetchData() {
      this.loading = true;

      let sign =
        this.options.user.sortDesc && this.options.user.sortDesc[0] ? "-" : "";

      let params = {
        page: this.options.user.page,
        limit: this.options.user.itemsPerPage,
        sort: "-id",
      };

      if (
        this.options.user.sortBy.length > 0 &&
        this.options.user.sortDesc.length > 0
      ) {
        params.sort =
          sign + (this.options.user.sortBy && this.options.user.sortBy[0]);
      }

      let response = await this.$get("admin/user/user", params);

      this.loading = false;

      if (response.status == 200) {
        this.dataGrid.user = response.results.data;
        this.totalData.user = parseInt(response.results.pagination.total_data);
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data.",
          color: "#f39c12",
        };
      }
    },
  },
};
</script>

<style>
</style>